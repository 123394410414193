import normalize from 'json-api-normalizer'

// @params
// jsonApiData:
//   {
//     data: {
//       attributes: {
//         email: 'xxxx',
//         name: 'bbbb'
//       },
//       id: '1',
//       type: 'admins'
//     }
//   }

// @return
//  Hash
//   {
//     admins: {
//       '1': {
//         id: '1',
//         type: 'admins',
//         attributes: {
//           email: 'xxxx',
//           name: 'bbbb'
//         }
//       }
//     }
//   }

const convertJsonApiData = (jsonApiData) => {
  const normalizedData = normalize(jsonApiData, { camelizeKeys: false })

  return Object.keys(normalizedData).reduce((result, key) => {
    const data = normalizedData[key]

    const modelName = camelize(key)
    const arrayData = Object.keys(data).reduce((result, id) => {
      const row = data[id]

      row.attributes.id = id

      result.push(row.attributes)

      return result
    }, [])

    result[modelName] = arrayData

    return result
  }, {})
}

const camelize = (str) => {
  // First, transform dashes and underscores as before
  let result = str.replace(/[-_]+(.)?/g, (match, chr) =>
    chr ? chr.toUpperCase() : ''
  )

  // Then, capitalize the first letter of the result
  // result = result.charAt(0).toUpperCase() + result.slice(1);

  return result
}

export const convertAndSaveResourcesToStore = (commit, jsonApiData) => {
  const convertedData = convertJsonApiData(jsonApiData)

  Object.keys(convertedData).forEach((modelName) => {
    commit(`${modelName}/SAVE_RESOURCES`, convertedData[modelName], {
      root: true
    })
  })
}
